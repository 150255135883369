import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LegoProject from '../components/LegoProject'
import { getSrc } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

const LegoPage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH LEGO'
  const imageSource = getSrc(data.file)

  return (
    <Layout>
      <SEO
        title={pageTitle}
        description={"Laurie's LEGO projects."}
        imageSource={imageSource}
        imageAlt={'LEGO logo'}
      />
      <section id="banner">
        <div id="banner" className="inner">
          <header>
            <h2 className="hs h2">LEGO Builds</h2>
          </header>
        </div>
      </section>
      <section id="speaking" className="wrapper style4 container">
        <div className="container">
          <div className="wrapper" id="speakwrap">
            {data.allLegoYaml.nodes.map(node => (
              <LegoProject project={node} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allLegoYaml(sort: { fields: [index], order: DESC }) {
      nodes {
        name
        url
        image {
          childImageSharp {
            gatsbyImageData(width: 500, transformOptions: { cropFocus: CENTER })
          }
        }
      }
    }
    file(relativePath: { eq: "headers/lego.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default LegoPage
