import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

function SEO({ title, description, meta = [], imageSource, imageAlt }) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        file(relativePath: { eq: "headers/default.jpg" }) {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.91)
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const image = imageSource
    ? `${site.siteMetadata.siteUrl}${imageSource}`
    : `${site.siteMetadata.siteUrl}${getSrc(file)}`
  const imageAltText = imageAlt || metaDescription

  return (
    <Helmet
      htmlAttributes={{
        lang: `en`,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat([
          {
            name: `og:image`,
            content: image,
          },
          {
            name: `og:image:alt`,
            content: imageAltText,
          },
          {
            name: `twitter:image`,
            content: image,
          },
          {
            name: `twitter:image:alt`,
            content: imageAltText,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
        ])
        .concat(meta)}
    />
  )
}

export default SEO
